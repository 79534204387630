<template>
  <!-- 关注我的 -->
  <!-- 我关注的 -->
  <div class="box">
    <div class="content" style="height: calc(100% - 40px); margin-bottom: 20px;" v-if="datalistnull">
      <TojoyErrorOption :blankData="blankData" />
    </div>
    <div class="content" v-if="!datalistnull">
      <infodata-attention v-for="(item, index) in attentionlistdata" :key="index" @routerinfoto="routerPagehome(item)" :datalist="item">
        <template slot="rightBar">
          <div v-if="status == 0"><i class="iconfont iconjiantou"></i></div>
          <div @click.stop="unlookUser(item)" class="unlook" v-if="status == 1">取消关注</div>
        </template>
      </infodata-attention>
      <div class="footer">
        <el-pagination background layout="prev, pager, next" :current-page="page" :page-size="size" :total="total" @current-change="handleCurrentChange"> </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { attentionMy, myAttention } from '@/api/employeedata.js'
import { cancel } from '@/api/thrclear.js'
import TojoyErrorOption from '@/components/business/blank/blank.vue'
import infodataAttention from '../../components/infodata/infodata-attention'
import { Message, MessageBox } from 'element-ui'
export default {
  name: 'personalCenter-attentionme',
  data() {
    return {
      total: 0,
      // 每页条数
      size: 10,
      // 当前页
      page: 1,
      attentionlistdata: [],
      value: 1,
      status: 0,
      datalistnull: false,
      blankData: {}
    }
  },
  created() {
    if (this.$route.path === '/personalCenter/myattention') {
      this.myAttention()
      this.status = 1
    } else {
      this.attentionMy()
      this.status = 0
    }
  },
  watch: {
    $route(to, from) {
      // 关注我的
      if (to.name == 'personalCenter-attentionme') {
        this.attentionMy()
        this.status = 0
        // 我关注的
      } else {
        this.myAttention()
        this.status = 1
      }
    }
  },
  components: {
    infodataAttention,
    TojoyErrorOption
  },
  methods: {
    ...mapActions('personcenter', ['loadCountData']),
    // 获取关注我的列表
    attentionMy() {
      let data = {
        page: this.page,
        size: this.size
      }
      attentionMy(data).then(res => {
        if (res && res.code === '000000') {
          this.total = res.data.total
          this.attentionlistdata = res.data.list
          if (res.data.list.length < 1) {
            this.datalistnull = true
            this.blankData = {
              type: 'notdata'
            }
          } else {
            this.datalistnull = false
          }
        } else {
          Message({
            message: '获取关注我的列表失败！',
            type: 'error'
          })
        }
      })
    },
    // 获取我关注的列表
    myAttention() {
      let data = {
        page: this.page,
        size: this.size
      }
      myAttention(data).then(res => {
        if (res && res.code === '000000') {
          this.total = res.data.total
          this.attentionlistdata = res.data.list
          if (res.data.list.length < 1) {
            this.datalistnull = true
            this.blankData = {
              type: 'notdata'
            }
          } else {
            this.datalistnull = false
          }
        } else {
          Message({
            message: '获取我关注的列表失败！',
            type: 'error'
          })
        }
      })
    },
    // 跳转到列表人员主页
    routerPagehome(val) {
      this.$router.push({
        name: 'homepage',
        query: {
          userId: val.userId
        }
      })
    },
    //取消关注
    unlookUser(val) {
      MessageBox.confirm(`确定取消关注${val.userName}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          attentionId: val.userId
        }
        cancel(data).then(res => {
          if (res && res.code === '000000') {
            Message({
              message: '已取消关注！',
              type: 'success'
            })
          }
          this.page = 1
          this.myAttention()
          this.loadCountData()
        })
      })
    },
    handleCurrentChange(val) {
      this.page = val
      if(this.status === 0){
        this.attentionMy()
      }else {
        this.myAttention()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  overflow: scroll;
  .content {
    margin: 20px 30px 70px;
    background: #fff;
    border-radius: 5px;
    .unlook {
      padding: 7px 10px;
      background: #f7f7f7;
      border-radius: 3px;
      font-size: 12px;
      color: #999999;
      flex-shrink: 0;
      margin-left: 20px;
      &:hover {
        color: $blue;
      }
    }
    .iconjiantou {
      font-size: 12px;
      color: #ccc;
    }
    .footer {
      height: 50px;
      position: fixed;
      bottom: 0;
      right: 0;
      background: #fff;
      width: calc(80% - 60px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
