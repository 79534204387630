<template>
  <div class="conter" @click="routerinfoto">
    <div class="header">
      <img v-if="datalist.avatar" :src="datalist.avatar" alt="" />
      <div v-else class="avatardefault">{{ datalist.userName.slice(-2) ? datalist.userName.slice(-2) : '' }}</div>
      <div class="text">
        <div class="name">{{ datalist.userName }}</div>
        <div class="department">{{ datalist.orgChain }}</div>
        <div class="department">{{ datalist.rankName }}</div>
      </div>
    </div>
    <slot name="rightBar"></slot>
  </div>
</template>
<script>
export default {
  props: {
    datalist: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    routerinfoto() {
      this.$emit('routerinfoto')
    }
  }
}
</script>
<style lang="scss" scoped>
.conter {
  padding: 16px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .header {
    height: 100%;
    display: flex;
    img {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .text {
      padding-left: 10px;
      .name {
        font-size: 14px;
        color: #333333;
      }
      .department {
        font-size: 12px;
        color: #999999;
        padding-top: 8px;
      }
    }
    .avatardefault {
      background: $blue;
      color: #fff;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  // &:hover {
  //   background: $hover-block;
  // }
}
</style>
